@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Inconsolata:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Concert+One&family=Inconsolata:wght@300&display=swap');

.projects-container {
    background-color: #c26867;
    min-height: 120vh;
    width: 100%;
}

.project-title{
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    font-size: 100px;
    float: right;
    opacity: .8;
    margin-top: -25px;
    margin-right: 25px;
}

.project-card-grid {
    display: grid;
    grid-template-columns: auto auto;
    width: 80%;
    position: absolute;
    margin-top: 100px;
    margin-left: 130px;
    grid-column-gap: 35px;
    grid-row-gap: 35px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
}

.project-card {
    width: 100%;
    height: 300px;
    position: relative;
    top: 30px;
    margin-left: 70px;
    background-color: #ccc;
    border-radius: 5px 5px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-card-top {
    height: 18px;
    display: inline;
}

.red-circle {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 3px;
    margin-left: 5px;
}

.yellow-circle {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: #facf5a;
    position: absolute;
    top: 3px;
    margin-left: 21px;
}

.green-circle {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: #2cb978;
    position: absolute;
    top: 3px;
    margin-left: 38px;
}

.project-card-info {
    position: absolute;
    background-color: #383737;
    height: 100%;
    top: 18px;
    border-radius: 0px 0px 10px 10px;
}

.project-card-link-holder{
    position: relative;
    bottom: 10px;
    display: grid;
    grid-template-columns: auto auto;
}

.project-card-link {
    font-family: 'Bungee Outline';
    color: white;
}

a:link {
    text-decoration: none;
}

.command-prompt {
    color: #f0f0f0;
    opacity: .8;
    text-align: left;
    margin-left: 15px;
    font-family: 'Inconsolata', monospace;
}

.command-response {
    color: #f0f0f0;
    opacity: .5;
    text-align: left;
    margin-left: 25px;
    font-family: 'Inconsolata', monospace;
}




@media only screen and (max-width: 800px){
    .projects-container {
        background-color: #c26867;
        min-height: 243vh;
        width: 100%;
    }
    
    .project-title{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        font-size: 100px;
        float: right;
        opacity: .8;
        margin-top: -25px;
        margin-right: 25px;
    }
    
    .project-card-grid {
        display: grid;
        grid-template-columns: auto auto;
        width: 85%;
        position: absolute;
        margin-top: 100px;
        margin-left: 100px;
        background-color: #f0f0f0;
        background: none;
        grid-column-gap: 20px;
        grid-row-gap: 35px;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
    }
    
    .project-card {
        width: 100%;
        height: 310px;
        position: relative;
        top: 30px;
        margin-left: 10px;
        background-color: #ccc;
        border-radius: 5px 5px 10px 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .project-card-top {
        height: 18px;
        display: inline;
    }
    
    .red-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: 3px;
        margin-left: 5px;
    }
    
    .yellow-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #facf5a;
        position: absolute;
        top: 3px;
        margin-left: 21px;
    }
    
    .green-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #2cb978;
        position: absolute;
        top: 3px;
        margin-left: 38px;
    }
    
    .project-card-info {
        position: absolute;
        background-color: #383737;
        height: 100%;
        top: 18px;
        border-radius: 0px 0px 10px 10px;
    }
    
    .project-card-link-holder{
        
        bottom: 10px;
        display: grid;
        grid-template-columns: auto auto;
    }
    
    .project-card-link {
        font-family: 'Bungee Outline';
        color: white;
    }
    
    a:link {
        text-decoration: none;
    }
    
    .command-prompt {
        color: #f0f0f0;
        opacity: .8;
        text-align: left;
        margin-left: 15px;
        font-family: 'Inconsolata', monospace;
    }
    
    .command-response {
        color: #f0f0f0;
        opacity: .5;
        text-align: left;
        margin-left: 25px;
        font-family: 'Inconsolata', monospace;
    }

}







@media only screen and (max-width: 450px){

    .projects-container {
        background-color: #c26867;
        min-height: 100vh;
        height: 200vh;
        width: 100%;
    }
    
    .project-title{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        font-size: 40px;
        float: right;
        opacity: .8;
        margin-top: 0px;
        margin-right: 50px;
    }
    
    .project-card-grid {
        display: grid;
        grid-template-columns: auto;
        width: 68%;
        position: absolute;
        margin-top: 30px;
        margin-left: 100px;
        background: none;
        grid-row-gap: 35px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
        
    }
    
    .project-card {
        width: 98%;
        height: 420px;
        position: relative;
        top: 30px;
        margin-left:0px;
        background-color: #ccc;
        border-radius: 5px 5px 10px 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
    }
    
    .project-card-top {
        height: 18px;
        display: inline;
        
    }
    
    .red-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: 3px;
        margin-left: 5px;
    }
    
    .yellow-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #facf5a;
        position: absolute;
        top: 3px;
        margin-left: 21px;
    }
    
    .green-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #2cb978;
        position: absolute;
        top: 3px;
        margin-left: 38px;
    }
    
    .project-card-info {
        position: absolute;
        background-color: #383737;
        height: 100%;
        top: 18px;
        border-radius: 0px 0px 10px 10px;
    }
    
    .project-card-link-holder{
        position: relative;
        bottom: 0px;
        display: grid;
        grid-template-columns: auto auto;
    }
    
    .project-card-link {
        font-family: 'Bungee Outline';
        color: white;
    }
    
    a:link {
        text-decoration: none;
    }
    
    .command-prompt {
        color: #f0f0f0;
        opacity: .8;
        text-align: left;
        margin-left: 15px;
        font-family: 'Inconsolata', monospace;
    }
    
    .command-response {
        color: #f0f0f0;
        opacity: .5;
        text-align: left;
        margin-left: 25px;
        font-family: 'Inconsolata', monospace;
        margin-right: 10px;
    }
    
}