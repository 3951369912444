@import url(https://fonts.googleapis.com/css2?family=Concert+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Concert+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Concert+One&family=Inconsolata:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Concert+One&family=Inconsolata:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Concert+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Concert+One&family=Inconsolata:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Concert+One&family=Inconsolata:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.intro-container{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.intro-title {
    font-family: 'Concert One', cursive;
    color: aliceblue;
    font-size: 80px;
    margin-top: 10px ;float: right;
    text-align: center;
    grid-column: 2/3;
}

.left-pannel{
    background-color: #facf5a;
    min-height: 100vh;
}

.half-half{
    height: 350px;
    float: left;
    grid-row: 2/3;
    transform: translateX(-50%);
}

.right-pannel{
    background-color:#364f6b;
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: .9fr 3fr .3fr;
}

.intro-text{
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 1.5em;
    align-self: center;
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    opacity: .8;
}


@media only screen and (max-width: 800px){
    .intro-container{
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .intro-title {
        grid-column: 2/3;
        font-family: 'Concert One', cursive;
        color: aliceblue;
        font-size: 50px;
        margin-top: 10px ;
        
    }

    .left-pannel{
        background-color: #facf5a;
        min-height: 100vh;
    }
    
    .half-half{
        height: 200px;
        float: left;
        grid-row: 2/3;
        transform: translateX(-50%);
    }
    
    .right-pannel{
        background-color:#364f6b;
        width: 100%;
        min-height: 100vh;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: .9fr 3fr .3fr;
    }
    
    .intro-text{
        grid-row: 2/3;
        grid-column: 2/3;
        font-size: 1.3em;
        align-self: center;
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        opacity: .8;

        line-height: 1.1;
    }
}



@media only screen and (max-width: 450px){
    .intro-container{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 100px 2.9fr;
}


.intro-title {
    position: absolute;
    font-family: 'Concert One', cursive;
    color: aliceblue;
    font-size: 80px;
    font-size: 50px;
    margin-top: 10px ;
    margin-left: 0px;
    text-align: center;
}

.left-pannel{
    background-color:#facf5a;
    grid-row: 1/2;
    grid-column: 1/2;
}

.half-half{
    height: 150px;
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: center;
    justify-self: center;
    float: none;
    margin-top: 150px;
    transform: translateX(0%);
}

.right-pannel{
    width: 100%;
    background-color:#364f6b;
    display: grid;
    grid-template-rows: 1fr 3fr;
    grid-template-columns: 1fr;
    grid-row: 1/2;
    grid-column: 2/3;
}

.intro-text{
    grid-column: 2/3;
    font-size: 1.5em;
    align-self: right;
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    opacity: .8;
    margin-left: 20px;
    margin-right: 10px;
    text-align: left;
    line-height: 1.2;
}
}
.face{
    position: absolute;
    top: -0px;
    height: 150px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: -10px;
}

a{
    text-decoration: none;
    color: black;
}

ul{
    position: absolute;
    top: 150px;
    margin-left: 0px;
}
li {
    width: 100px;
    height: 100px;
    list-style: none;
    margin-top: -30px;
}

.click-me{
    position: absolute;
    height: 100px;
    width: 100px;
    margin-top: 150px;
    margin-left: 50px;

}

.icon{
    width: 40px;
    height: 40px;
}

.menuHolder{
    position: fixed;
    top: 20px;
}

.scrollLink {
    width: 50px;
    height: 50px;
    background-color: aliceblue;
}

@media only screen and (max-width: 450px){
    .face{
        position: absolute;
        top: -0px;
        height: 80px;
        width: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: -5px;
    }
    
    ul{
        position: absolute;
        top: 80px;
        margin-top: -20px;
        margin-left: -3px;
    }
    li {
        width: 30px;
        height: 30px;
        list-style: none;
        margin-top: 30px;
    }
    
    .click-me{
        position: absolute;
        height: 80px;
        width: 80px;
        margin-top: 90px;
        margin-left: 20px;
    
    }
    
    .icon{
        width: 30px;
        height: 30px;
        margin-top: 10px;
    }
    
    .link-text-home{
        margin-left: -7px;
    }

    .link-text-projects{
        margin-left: -13px;
    }

    .link-text-experience{
        margin-left: -23px;
    }

    .link-text-email{
        margin-left: -5px;
    }

    .link-text-resume{
        margin-left: -13px;
    }
    
    .menuHolder{
        position: fixed;
        top: 20px;
    }
    
    .scrollLink {
        width: 50px;
        height: 50px;
        background-color: aliceblue;
    }
}


@media only screen and (max-width: 800px){
    .face{
        position: absolute;
        top: -0px;
        height: 80px;
        width: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: -5px;
    }
    
    ul{
        position: absolute;
        top: 80px;
        margin-top: -20px;
        margin-left: -3px;
    }
    li {
        width: 30px;
        height: 30px;
        list-style: none;
        margin-top: 20px;
    }
    
    .click-me{
        position: absolute;
        height: 80px;
        width: 80px;
        margin-top: 90px;
        margin-left: 20px;
    
    }
    
    .icon{
        width: 30px;
        height: 30px;
    }
    
    .link-text-home{
        margin-left: -7px;
    }

    .link-text-projects{
        margin-left: -13px;
    }

    .link-text-experience{
        margin-left: -23px;
    }
    
    .menuHolder{
        position: fixed;
        top: 20px;
    }
    
    .scrollLink {
        width: 50px;
        height: 50px;
        background-color: aliceblue;
    }
}
.projects-container {
    background-color: #c26867;
    min-height: 120vh;
    width: 100%;
}

.project-title{
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    font-size: 100px;
    float: right;
    opacity: .8;
    margin-top: -25px;
    margin-right: 25px;
}

.project-card-grid {
    display: grid;
    grid-template-columns: auto auto;
    width: 80%;
    position: absolute;
    margin-top: 100px;
    margin-left: 130px;
    grid-column-gap: 35px;
    grid-row-gap: 35px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
}

.project-card {
    width: 100%;
    height: 300px;
    position: relative;
    top: 30px;
    margin-left: 70px;
    background-color: #ccc;
    border-radius: 5px 5px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.project-card-top {
    height: 18px;
    display: inline;
}

.red-circle {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 3px;
    margin-left: 5px;
}

.yellow-circle {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: #facf5a;
    position: absolute;
    top: 3px;
    margin-left: 21px;
}

.green-circle {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    background-color: #2cb978;
    position: absolute;
    top: 3px;
    margin-left: 38px;
}

.project-card-info {
    position: absolute;
    background-color: #383737;
    height: 100%;
    top: 18px;
    border-radius: 0px 0px 10px 10px;
}

.project-card-link-holder{
    position: relative;
    bottom: 10px;
    display: grid;
    grid-template-columns: auto auto;
}

.project-card-link {
    font-family: 'Bungee Outline';
    color: white;
}

a:link {
    text-decoration: none;
}

.command-prompt {
    color: #f0f0f0;
    opacity: .8;
    text-align: left;
    margin-left: 15px;
    font-family: 'Inconsolata', monospace;
}

.command-response {
    color: #f0f0f0;
    opacity: .5;
    text-align: left;
    margin-left: 25px;
    font-family: 'Inconsolata', monospace;
}




@media only screen and (max-width: 800px){
    .projects-container {
        background-color: #c26867;
        min-height: 243vh;
        width: 100%;
    }
    
    .project-title{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        font-size: 100px;
        float: right;
        opacity: .8;
        margin-top: -25px;
        margin-right: 25px;
    }
    
    .project-card-grid {
        display: grid;
        grid-template-columns: auto auto;
        width: 85%;
        position: absolute;
        margin-top: 100px;
        margin-left: 100px;
        background-color: #f0f0f0;
        background: none;
        grid-column-gap: 20px;
        grid-row-gap: 35px;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
    }
    
    .project-card {
        width: 100%;
        height: 310px;
        position: relative;
        top: 30px;
        margin-left: 10px;
        background-color: #ccc;
        border-radius: 5px 5px 10px 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .project-card-top {
        height: 18px;
        display: inline;
    }
    
    .red-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: 3px;
        margin-left: 5px;
    }
    
    .yellow-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #facf5a;
        position: absolute;
        top: 3px;
        margin-left: 21px;
    }
    
    .green-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #2cb978;
        position: absolute;
        top: 3px;
        margin-left: 38px;
    }
    
    .project-card-info {
        position: absolute;
        background-color: #383737;
        height: 100%;
        top: 18px;
        border-radius: 0px 0px 10px 10px;
    }
    
    .project-card-link-holder{
        
        bottom: 10px;
        display: grid;
        grid-template-columns: auto auto;
    }
    
    .project-card-link {
        font-family: 'Bungee Outline';
        color: white;
    }
    
    a:link {
        text-decoration: none;
    }
    
    .command-prompt {
        color: #f0f0f0;
        opacity: .8;
        text-align: left;
        margin-left: 15px;
        font-family: 'Inconsolata', monospace;
    }
    
    .command-response {
        color: #f0f0f0;
        opacity: .5;
        text-align: left;
        margin-left: 25px;
        font-family: 'Inconsolata', monospace;
    }

}







@media only screen and (max-width: 450px){

    .projects-container {
        background-color: #c26867;
        min-height: 100vh;
        height: 200vh;
        width: 100%;
    }
    
    .project-title{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        font-size: 40px;
        float: right;
        opacity: .8;
        margin-top: 0px;
        margin-right: 50px;
    }
    
    .project-card-grid {
        display: grid;
        grid-template-columns: auto;
        width: 68%;
        position: absolute;
        margin-top: 30px;
        margin-left: 100px;
        background: none;
        grid-row-gap: 35px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
        
    }
    
    .project-card {
        width: 98%;
        height: 420px;
        position: relative;
        top: 30px;
        margin-left:0px;
        background-color: #ccc;
        border-radius: 5px 5px 10px 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
    }
    
    .project-card-top {
        height: 18px;
        display: inline;
        
    }
    
    .red-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: 3px;
        margin-left: 5px;
    }
    
    .yellow-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #facf5a;
        position: absolute;
        top: 3px;
        margin-left: 21px;
    }
    
    .green-circle {
        height: 11px;
        width: 11px;
        border-radius: 50%;
        background-color: #2cb978;
        position: absolute;
        top: 3px;
        margin-left: 38px;
    }
    
    .project-card-info {
        position: absolute;
        background-color: #383737;
        height: 100%;
        top: 18px;
        border-radius: 0px 0px 10px 10px;
    }
    
    .project-card-link-holder{
        position: relative;
        bottom: 0px;
        display: grid;
        grid-template-columns: auto auto;
    }
    
    .project-card-link {
        font-family: 'Bungee Outline';
        color: white;
    }
    
    a:link {
        text-decoration: none;
    }
    
    .command-prompt {
        color: #f0f0f0;
        opacity: .8;
        text-align: left;
        margin-left: 15px;
        font-family: 'Inconsolata', monospace;
    }
    
    .command-response {
        color: #f0f0f0;
        opacity: .5;
        text-align: left;
        margin-left: 25px;
        font-family: 'Inconsolata', monospace;
        margin-right: 10px;
    }
    
}
.resume-container{
    background-color: #facf5a;
    min-height: 100vh;
    width: 100%;
    top:20px;
}


.resume-label{
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    text-align: left;
    margin-left: 50px;
    font-size: 34px;;
    opacity: .8;
}

.resume {
    top: -250px;
    width: 80%;
    margin-left: 10%;
    display: grid;
    grid-template-columns: .5fr 1.5fr;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.experience-container {
    width: 100%;
    height: auto;
    border-radius: 15px;
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.experience {
    width: 100%;
    height: auto;
    border-radius: 15px;
    background-color: #fafaf6;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.experience-title {
    text-align: right;
    margin-right: 15px;
    font-family: 'Catamaran', sans-serif;
}

.experience-date {
    text-align: right;
    margin-right: 20px;
    position: relative;
    top: -15px;
    font-size: 14px;
    font-family: 'Catamaran', sans-serif;
}

.experience-company {
    text-align: left;
    margin-left: 15px;
    position: relative;
    top: -30px;
    font-family: 'Catamaran', sans-serif;
}

.experience-description {
    text-align: left;
    margin-left: 15px;
    position: relative;
    top: -40px;
    font-family: 'Catamaran';
}

.education-container{
    width: 100%;
    height: auto;
    background-color: #fafaf6;
    border-radius: 15px;
    background-image: url(/static/media/pic.c8825506.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.education-school{
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 20px;
}

.education-degree{
    margin-top: -20px;
    font-size: 19px;
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 20px;
}

.education-concentration{
    margin-top: -20px;
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 20px;
}

.education-date{
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 30px;
}

.pro-languages {
    font-family: 'Catamaran', sans-serif, bold;
    font-size: 30px;
    margin-top: 45px;
    align-self: flex-start;
}


@media only screen and (max-width: 800px){
    .resume-container{
        background-color: #facf5a;
        min-height: 100vh;
        width: 100%;
        top:20px;
    }
    
    
    .resume-label{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        text-align: left;
        font-size: 34px;;
        opacity: .8;
        margin-top: -5px;
    }
    
    .resume {
        top: -250px;
        width: 80%;
        margin-left: 10%;
        display: grid;
        grid-template-columns: .5fr 1.5fr;
        grid-row-gap: 20px;
        row-gap: 20px;
        grid-column-gap: 20px;
        -webkit-column-gap: 20px;
                column-gap: 20px;;
    }
    
    .experience-container {
        width: 100%;
        height: auto;
        border-radius: 15px;
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 20px;
        -webkit-column-gap: 20px;
                column-gap: 20px;
    }
    
    .experience {
        width: 100%;
        height: auto;
        border-radius: 15px;
        background-color: #fafaf6;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .experience-title {
        text-align: right;
        margin-right: 15px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-date {
        text-align: right;
        margin-right: 20px;
        position: relative;
        top: -15px;
        font-size: 14px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-company {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -30px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-description {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -40px;
        font-family: 'Catamaran';
    }
    
    .education-container{
        width: 100%;
        height: auto;
        background-color: #fafaf6;
        border-radius: 15px;
        background-image: url(/static/media/pic.c8825506.png);
        background-size: 170px;
        background-repeat: no-repeat;
        background-position-x: right;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .education-school{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-degree{
        margin-top: -20px;
        font-size: 19px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-concentration{
        margin-top: -20px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-date{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 30px;
    }
    
    .pro-languages {
        font-family: 'Catamaran', sans-serif, bold;
        align-self: flex-start;
        margin-top: 15px;
    }
}


@media only screen and (max-width: 450px){
    .resume-container{
        background-color: #facf5a;
        min-height: 100vh;
        width: 100vw;
        top:20px;
    }
    
    
    .resume-label{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        text-align: left;
        margin-left: 0px;
        font-size: 34px;;
        opacity: .8;
        text-align: left;
        justify-self: left;
        margin-top: 10px;
    }
    
    .resume {
        top: -250px;
        width: 75%;
        margin-left: 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 0px;
        row-gap: 0px;
        margin-left: 100px;
    }
    
    .experience-container {
        width: 96%;
        height: auto;
        border-radius: 15px;
        display: grid;
        grid-template-columns: auto ;
        grid-row-gap: 15px;
        row-gap: 15px;
        grid-column-gap: 20px;
        -webkit-column-gap: 20px;
                column-gap: 20px;
    }
    
    .experience {
        width: 94%;
        height: auto;
        border-radius: 15px;
        background-color: #fafaf6;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
    }
    
    .experience-title {
        text-align: right;
        margin-right: 15px;
        font-family: 'Catamaran', sans-serif;
        
    }
    
    .experience-date {
        text-align: right;
        margin-right: 20px;
        position: relative;
        top: -15px;
        font-size: 14px;
        font-family: 'Catamaran', sans-serif;
        
    }
    
    .experience-company {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -30px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-description {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -40px;
        font-family: 'Catamaran';
    }
    
    .education-container{
        width: 90%;
        height: auto;
        background-color: #fafaf6;
        border-radius: 15px;
        background-image: url(/static/media/pic.c8825506.png);
        background-size: 85px;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .education-school{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
    }
    
    .education-degree{
        margin-top: -20px;
        font-size: 19px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-concentration{
        margin-top: -20px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-date{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 30px;
    }
    
    .pro-languages {
        font-family: 'Catamaran', sans-serif, bold;
        align-self: flex-start;
        font-size: 1.5em;
        text-align: left;
        margin-top: -0px;
        margin-left: 20px;
    }

}
