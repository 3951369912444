.face{
    position: absolute;
    top: -0px;
    height: 150px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: -10px;
}

a{
    text-decoration: none;
    color: black;
}

ul{
    position: absolute;
    top: 150px;
    margin-left: 0px;
}
li {
    width: 100px;
    height: 100px;
    list-style: none;
    margin-top: -30px;
}

.click-me{
    position: absolute;
    height: 100px;
    width: 100px;
    margin-top: 150px;
    margin-left: 50px;

}

.icon{
    width: 40px;
    height: 40px;
}

.menuHolder{
    position: fixed;
    top: 20px;
}

.scrollLink {
    width: 50px;
    height: 50px;
    background-color: aliceblue;
}

@media only screen and (max-width: 450px){
    .face{
        position: absolute;
        top: -0px;
        height: 80px;
        width: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: -5px;
    }
    
    ul{
        position: absolute;
        top: 80px;
        margin-top: -20px;
        margin-left: -3px;
    }
    li {
        width: 30px;
        height: 30px;
        list-style: none;
        margin-top: 30px;
    }
    
    .click-me{
        position: absolute;
        height: 80px;
        width: 80px;
        margin-top: 90px;
        margin-left: 20px;
    
    }
    
    .icon{
        width: 30px;
        height: 30px;
        margin-top: 10px;
    }
    
    .link-text-home{
        margin-left: -7px;
    }

    .link-text-projects{
        margin-left: -13px;
    }

    .link-text-experience{
        margin-left: -23px;
    }

    .link-text-email{
        margin-left: -5px;
    }

    .link-text-resume{
        margin-left: -13px;
    }
    
    .menuHolder{
        position: fixed;
        top: 20px;
    }
    
    .scrollLink {
        width: 50px;
        height: 50px;
        background-color: aliceblue;
    }
}


@media only screen and (max-width: 800px){
    .face{
        position: absolute;
        top: -0px;
        height: 80px;
        width: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: -5px;
    }
    
    ul{
        position: absolute;
        top: 80px;
        margin-top: -20px;
        margin-left: -3px;
    }
    li {
        width: 30px;
        height: 30px;
        list-style: none;
        margin-top: 20px;
    }
    
    .click-me{
        position: absolute;
        height: 80px;
        width: 80px;
        margin-top: 90px;
        margin-left: 20px;
    
    }
    
    .icon{
        width: 30px;
        height: 30px;
    }
    
    .link-text-home{
        margin-left: -7px;
    }

    .link-text-projects{
        margin-left: -13px;
    }

    .link-text-experience{
        margin-left: -23px;
    }
    
    .menuHolder{
        position: fixed;
        top: 20px;
    }
    
    .scrollLink {
        width: 50px;
        height: 50px;
        background-color: aliceblue;
    }
}