@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');

.intro-container{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.intro-title {
    font-family: 'Concert One', cursive;
    color: aliceblue;
    font-size: 80px;
    margin-top: 10px ;float: right;
    text-align: center;
    grid-column: 2/3;
}

.left-pannel{
    background-color: #facf5a;
    min-height: 100vh;
}

.half-half{
    height: 350px;
    float: left;
    grid-row: 2/3;
    transform: translateX(-50%);
}

.right-pannel{
    background-color:#364f6b;
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: .9fr 3fr .3fr;
}

.intro-text{
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 1.5em;
    align-self: center;
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    opacity: .8;
}


@media only screen and (max-width: 800px){
    .intro-container{
        width: 100vw;
        height: 100vh;
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .intro-title {
        grid-column: 2/3;
        font-family: 'Concert One', cursive;
        color: aliceblue;
        font-size: 50px;
        margin-top: 10px ;
        
    }

    .left-pannel{
        background-color: #facf5a;
        min-height: 100vh;
    }
    
    .half-half{
        height: 200px;
        float: left;
        grid-row: 2/3;
        transform: translateX(-50%);
    }
    
    .right-pannel{
        background-color:#364f6b;
        width: 100%;
        min-height: 100vh;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: .9fr 3fr .3fr;
    }
    
    .intro-text{
        grid-row: 2/3;
        grid-column: 2/3;
        font-size: 1.3em;
        align-self: center;
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        opacity: .8;

        line-height: 1.1;
    }
}



@media only screen and (max-width: 450px){
    .intro-container{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 100px 2.9fr;
}


.intro-title {
    position: absolute;
    font-family: 'Concert One', cursive;
    color: aliceblue;
    font-size: 80px;
    font-size: 50px;
    margin-top: 10px ;
    margin-left: 0px;
    text-align: center;
}

.left-pannel{
    background-color:#facf5a;
    grid-row: 1/2;
    grid-column: 1/2;
}

.half-half{
    height: 150px;
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: center;
    justify-self: center;
    float: none;
    margin-top: 150px;
    transform: translateX(0%);
}

.right-pannel{
    width: 100%;
    background-color:#364f6b;
    display: grid;
    grid-template-rows: 1fr 3fr;
    grid-template-columns: 1fr;
    grid-row: 1/2;
    grid-column: 2/3;
}

.intro-text{
    grid-column: 2/3;
    font-size: 1.5em;
    align-self: right;
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    opacity: .8;
    margin-left: 20px;
    margin-right: 10px;
    text-align: left;
    line-height: 1.2;
}
}