@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Inconsolata:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Concert+One&family=Inconsolata:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300&display=swap'); 

.resume-container{
    background-color: #facf5a;
    min-height: 100vh;
    width: 100%;
    top:20px;
}


.resume-label{
    font-family: 'Concert One', cursive;
    color: #f0f0f0;
    text-align: left;
    margin-left: 50px;
    font-size: 34px;;
    opacity: .8;
}

.resume {
    top: -250px;
    width: 80%;
    margin-left: 10%;
    display: grid;
    grid-template-columns: .5fr 1.5fr;
    row-gap: 20px;
}

.experience-container {
    width: 100%;
    height: auto;
    border-radius: 15px;
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 15px;
    column-gap: 20px;
}

.experience {
    width: 100%;
    height: auto;
    border-radius: 15px;
    background-color: #fafaf6;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.experience-title {
    text-align: right;
    margin-right: 15px;
    font-family: 'Catamaran', sans-serif;
}

.experience-date {
    text-align: right;
    margin-right: 20px;
    position: relative;
    top: -15px;
    font-size: 14px;
    font-family: 'Catamaran', sans-serif;
}

.experience-company {
    text-align: left;
    margin-left: 15px;
    position: relative;
    top: -30px;
    font-family: 'Catamaran', sans-serif;
}

.experience-description {
    text-align: left;
    margin-left: 15px;
    position: relative;
    top: -40px;
    font-family: 'Catamaran';
}

.education-container{
    width: 100%;
    height: auto;
    background-color: #fafaf6;
    border-radius: 15px;
    background-image: url(../assets/pic.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.education-school{
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 20px;
}

.education-degree{
    margin-top: -20px;
    font-size: 19px;
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 20px;
}

.education-concentration{
    margin-top: -20px;
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 20px;
}

.education-date{
    font-family: 'Catamaran', sans-serif;
    text-align: left;
    margin-left: 30px;
}

.pro-languages {
    font-family: 'Catamaran', sans-serif, bold;
    font-size: 30px;
    margin-top: 45px;
    align-self: flex-start;
}


@media only screen and (max-width: 800px){
    .resume-container{
        background-color: #facf5a;
        min-height: 100vh;
        width: 100%;
        top:20px;
    }
    
    
    .resume-label{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        text-align: left;
        font-size: 34px;;
        opacity: .8;
        margin-top: -5px;
    }
    
    .resume {
        top: -250px;
        width: 80%;
        margin-left: 10%;
        display: grid;
        grid-template-columns: .5fr 1.5fr;
        row-gap: 20px;
        column-gap: 20px;;
    }
    
    .experience-container {
        width: 100%;
        height: auto;
        border-radius: 15px;
        display: grid;
        grid-template-columns: auto;
        row-gap: 15px;
        column-gap: 20px;
    }
    
    .experience {
        width: 100%;
        height: auto;
        border-radius: 15px;
        background-color: #fafaf6;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .experience-title {
        text-align: right;
        margin-right: 15px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-date {
        text-align: right;
        margin-right: 20px;
        position: relative;
        top: -15px;
        font-size: 14px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-company {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -30px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-description {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -40px;
        font-family: 'Catamaran';
    }
    
    .education-container{
        width: 100%;
        height: auto;
        background-color: #fafaf6;
        border-radius: 15px;
        background-image: url(../assets/pic.png);
        background-size: 170px;
        background-repeat: no-repeat;
        background-position-x: right;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .education-school{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-degree{
        margin-top: -20px;
        font-size: 19px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-concentration{
        margin-top: -20px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-date{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 30px;
    }
    
    .pro-languages {
        font-family: 'Catamaran', sans-serif, bold;
        align-self: flex-start;
        margin-top: 15px;
    }
}


@media only screen and (max-width: 450px){
    .resume-container{
        background-color: #facf5a;
        min-height: 100vh;
        width: 100vw;
        top:20px;
    }
    
    
    .resume-label{
        font-family: 'Concert One', cursive;
        color: #f0f0f0;
        text-align: left;
        margin-left: 0px;
        font-size: 34px;;
        opacity: .8;
        text-align: left;
        justify-self: left;
        margin-top: 10px;
    }
    
    .resume {
        top: -250px;
        width: 75%;
        margin-left: 0px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 0px;
        margin-left: 100px;
    }
    
    .experience-container {
        width: 96%;
        height: auto;
        border-radius: 15px;
        display: grid;
        grid-template-columns: auto ;
        row-gap: 15px;
        column-gap: 20px;
    }
    
    .experience {
        width: 94%;
        height: auto;
        border-radius: 15px;
        background-color: #fafaf6;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
    }
    
    .experience-title {
        text-align: right;
        margin-right: 15px;
        font-family: 'Catamaran', sans-serif;
        
    }
    
    .experience-date {
        text-align: right;
        margin-right: 20px;
        position: relative;
        top: -15px;
        font-size: 14px;
        font-family: 'Catamaran', sans-serif;
        
    }
    
    .experience-company {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -30px;
        font-family: 'Catamaran', sans-serif;
    }
    
    .experience-description {
        text-align: left;
        margin-left: 15px;
        position: relative;
        top: -40px;
        font-family: 'Catamaran';
    }
    
    .education-container{
        width: 90%;
        height: auto;
        background-color: #fafaf6;
        border-radius: 15px;
        background-image: url(../assets/pic.png);
        background-size: 85px;
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
    .education-school{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
    }
    
    .education-degree{
        margin-top: -20px;
        font-size: 19px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-concentration{
        margin-top: -20px;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 20px;
    }
    
    .education-date{
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        margin-left: 30px;
    }
    
    .pro-languages {
        font-family: 'Catamaran', sans-serif, bold;
        align-self: flex-start;
        font-size: 1.5em;
        text-align: left;
        margin-top: -0px;
        margin-left: 20px;
    }

}